import React from "react"
import Markdown from 'markdown-to-jsx'
import classNames from "classnames";
import { useState } from "react";

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo";
import Header from '../components/header';
import CaseList from '../components/case-list';
import { graphql } from 'gatsby';
import { useEffect } from "react"
import { useContext } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"
import BlogList from "../components/blog-list";
import Link from "../components/link";
import box_view from "../images/box-view.svg"; 
import list_view from "../images/list-view.svg"; 
import { CustomHr } from "../components/CustomHr";


const TagsDetail = props => {
  const { footers, headers, tags, general } = props.data.strapi;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const { tag } = props.data.strapi;
  const { copyright } = tag;
  const currentLanguage = props.pageContext.language;
  let isGermanPage;
  if (typeof window !== "undefined") {
    const location = window.location.pathname.indexOf("/de/") > -1;
    isGermanPage = location
  }

  const language  = 'de'
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices; 
  const caseStudies = props.data.strapi.tag.case_studies.filter((caseStudy) => (isGermanPage ? 'de' : 'en') === caseStudy.language);
  const blogs = tag?.blogs.filter((blog) => !blog.inactive && (isGermanPage ? 'de' : 'en') === blog.language); 
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  
  // getting  page first H1 to set as meta title and description if not set in strapi .
  const heading = tag.tag;
  const seoRichText = tag.seoRichText;

  const seoTitle = "" || tag && tag.seo && tag.seo.metaData.metaTitle || "Case studies"+" "+ heading ? heading : "Cases";
  const seoDescription = "" || tag && tag.seo && tag.seo.metaData.metaDescription || "Case studies" + " "+ heading ? heading : "Cases";
  const seoKeywords = "" || tag && tag.seo && tag.seo?.metaData?.keywords;
  const dispatch = useContext(GlobalDispatchContext)
  const state = useContext(GlobalStateContext);
  const isSubOpen = state.siteSubNav;
  const [tagsList, setTagsList] = useState([]); 
  const [scrollPos, setScrollPos] = useState(0);
  const [activeDisplay, setActiveDisplay] = useState("");
  const darkMode = state.darkModePreference === 'moon'; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }

  const handleClick = (state) => {
    const isBrowser = typeof window !== "undefined";
    document.body.scrollTo(0,0);
    if (isBrowser) {
      localStorage.setItem("preferredView", state)
    }    
    setActiveDisplay(state); 
  }

  useEffect(() => {
    const id = "case-studies"; 
    const isBrowser = typeof window !== "undefined";

    if (isBrowser) {
      const preferredView = window.localStorage.getItem("preferredView"); 
      if(preferredView) {
        setActiveDisplay(preferredView)
      }
    }
    if (isSubOpen[id]) {
      dispatch({ type: "RESET_SITE_SUB_NAV"});
    } 
    const handleScroll = () => {
      setScrollPos(
        typeof document !== "undefined" ? document.body.scrollTop : 0
      ); // TODO - Throttle to optimize
    };
    document.body.addEventListener("scroll", handleScroll);
     return () => {
      typeof document !== "undefined" &&
        document.body.removeEventListener("scroll", handleScroll);
    };
  }, []); 

  useEffect(() => {
    const filteredTags = tags.filter((tag) => tag?.active); 
    setTagsList([{tag:"alleprojekte", active: true}, ...filteredTags]); 
  }, [tags]); 

   const redirectTo = "/en";
   let anchorLinks = []; 

   return (
   <Layout header={header} footer={footer} language={language} redirectTo={redirectTo} anchorLinks={[] || anchorLinks}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="service-detail" 
        title={seoTitle} 
        description={seoDescription}  
        keywords={seoKeywords} 
        darkTheme={tag.darkTheme}  
        darkMode={darkMode}
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo} anchorLinks={anchorLinks}>
        <section className="section section-small-margin" style={{backgroundColor: sectionBackgroundColor}}>
          {heading && (
            <Header heading={heading ? heading : ''} withFullWidth={true} hasHash={true} />
          )}
          <span className="switch-btn switch-btn-layout">
            <ul 
              className={classNames("box", {"active": activeDisplay === ""})}
              onClick={() => handleClick("")}
            >
              <img src={box_view} />    
            </ul>
            <ul 
              className={classNames("menu ", {"active": activeDisplay === "list"})}
              onClick={() => handleClick("list")}
            >
              <img src={list_view} />    
           </ul>
          </span>
          {(caseStudies && caseStudies.length || blogs && blogs.length) ? (
            <div className="container">
              <BlogList 
                blogEntries={[...caseStudies, ...blogs]} 
                isCompact={false} 
                isTagsDetailPage={true} 
                activeDisplay={activeDisplay} 
                showMobileImages={true}
                imageBorderRadiusForServices={imageBorderRadiusForServices}
                hashtagsBackgroundColor={displayedHashtagBgColor}
                pageTransitionBgColor={pageTransitionBgColor}
                sectionBackgroundColor={sectionBackgroundColor}
              />
            </div>
          ) : ("")}
        </section>
        {seoRichText && (
          <section className="section" style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
               <div className="offset-md-1">
                 <div className="row">
                   <Header heading={seoRichText.heading} withFullWidth={true} />
                 </div>
                 <div className="row">
                  <div className="col offset-md-1">
                    <div className="case-list__body rich-text">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {seoRichText.richText || ''}
                      </Markdown> 
                    </div>             
                  </div>
                 </div>
               </div>
            </div>
          </section>
        )}
      </SiteHeaderAlt>
    </Layout>
  ) 
}

export const pageQuery = graphql`
  query TagsDetail($id: ID!) {
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor   
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode         
        fontColorDarkMode
        fontColorLightMode
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: "de"}) {
        ...SiteHeader
      }
      footers(where: { language: "de" }) {
        ...SiteFooter
      }
      tags {
        tag
        active
      }
      tag(id: $id) {
        copyright
        darkTheme
        tag
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        seoRichText {
          heading
          richText
        }
        case_studies {
          language
          inactive
          headerImageMobile {
          url
          }
          headerImage {
          url
          }
          title
          slug
          tags {
           tag
           active
          }
          previewImage {
          url 
          }
          description
          backgroundColor
          date
        }
        blogs {
          ...BlogListItem
        }
      }
    }
  }
`

export default TagsDetail; 
